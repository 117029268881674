<template>
	<aside class="messages">
		<span class="close" @click="close"></span>
		<transition name="component-fade" mode="out-in">
			<span class="back" @click="back" v-if="selectedMessage"></span>
		</transition>
		<div class="header">
			<h2>{{ headline }}</h2>
		</div>
		<transition name="component-fade" mode="out-in">
			<message-thread-list
				v-if="!selectedMessage"
				@selectMessage="selectMessage"
			></message-thread-list>
			<message-chat v-else :recordUUID="selectedMessage"></message-chat>
		</transition>
	</aside>
</template>

<script>
export default {
	props: {
		recordUUID: {
			type: String
		}
	},
	components: {
		messageThreadList: () =>
			import('@/components/messages/message-thread-list.vue'),
		messageChat: () => import('@/components/messages/messages-chat.vue')
	},
	data() {
		return {
			selectedMessage: null,
			headline: 'Nachrichten'
		};
	},
	watch: {
		selectedMessage: async function (val) {
			if (val) {
				this.headline = await this.getRecordNumber();
			} else {
				this.headline = 'Nachrichten';
			}
		}
	},
	mounted() {
		if (typeof this.recordUUID === 'string') {
			this.selectMessage(this.recordUUID);
		}
	},
	methods: {
		close() {
			this.selectedMessage = null;
			this.$emit('close');
		},
		back() {
			this.selectedMessage = null;
		},
		selectMessage(uuid) {
			this.selectedMessage = uuid;
		},
		async getRecordNumber() {
			const recordName = await this.$api.post(
				'/user/get_record_number',
				{ recordUUID: this.selectedMessage },
				{
					headers: {
						Authorization: `Bearer ${this.$store.getters.getUserToken}`
					}
				}
			);

			return recordName.data.id;
		}
	}
};
</script>

<style lang="scss">
.messages {
	position: fixed;
	left: auto;
	top: auto;
	right: 0%;
	bottom: 0%;
	z-index: 999999;
	display: block;
	width: 450px;
	height: 550px;
	margin-right: 100px;
	padding-right: 0px;
	padding-left: 0px;
	border-radius: 10px 10px 0px 0px;
	background-color: #fff;
	box-shadow: 1px 1px 19px -4px #acacac;
	display: grid;
	grid-template-rows: 1fr 10fr;
	grid-template-columns: 1fr;
	resize: vertical;
	.close {
		position: absolute;
		top: 0.5rem;
		right: 0.5rem;
		display: block;
		font-size: 0;
		height: 30px;
		width: 30px;
		&:before,
		&:after {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 3px;
			height: 20px;
			background-color: $text-color;
			transform: rotate(45deg) translate(-50%, -50%);
			transform-origin: top left;
			transition: all 420ms;
			content: '<';
		}
		&:after {
			transform: rotate(-45deg) translate(-50%, -50%);
		}
	}
	.back {
		position: absolute;
		top: 0.5rem;
		left: 0.5rem;
		display: block;
		font-size: 0;
		height: 30px;
		width: 30px;
		&:after {
			border-bottom-style: solid;
			border-bottom-width: 3px;
			border-right-style: solid;
			border-right-width: 3px;
			content: '';
			display: inline-block;
			top: 25%;
			left: 25%;
			width: 10px;
			height: 10px;
			position: absolute;
			transform: rotate(135deg);
		}
	}
	.header {
		padding: 2rem 0 0.5rem 0;
		width: 100%;
		text-align: center;
		border-bottom: 1px solid $dark-grey;
	}
}
.component-fade-enter-active,
.component-fade-leave-active {
	transition: opacity 0.2s ease;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
	opacity: 0;
}
</style>
